import React, { useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Navbar from 'components/Navbar';
import Sidebar from 'components/Sidebar';
import { useGetUserQuery } from 'state/api';

const Layout = () => {
	const isNonMobile = useMediaQuery('(min-width: 600px)');
	const [isSidebarOpen, setIsSidebarOpen] = useState(true);
	const userId = useSelector((state) => state.global.userId);
	const { data } = useGetUserQuery(userId);
	console.log('User data response: ', data);

	const updateSidebar = () => {
		return setIsSidebarOpen(!isSidebarOpen);
	};
	const closeSidebar = () => setIsSidebarOpen(false);
	return (
		<Box display={isNonMobile ? 'flex' : 'block'} width='100%' height='100%'>
			<Sidebar
				user={data || {}}
				isNonMobile={isNonMobile}
				drawerWidth='280px'
				isSidebarOpen={isSidebarOpen}
				updateSidebar={updateSidebar}
				closeSidebar={closeSidebar}
			/>
			<Box flexGrow={1}>
				<Navbar
					user={data || {}}
					isSidebarOpen={isSidebarOpen}
					updateSidebar={updateSidebar}
				/>
				<Outlet />
			</Box>
		</Box>
	);
};

export default Layout;
